import React from 'react'
import Link from 'gatsby-link'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import size from 'lodash/size'
import Img from "gatsby-image";
import './style.scss'

class CardPost extends React.Component {
  description(body) {
    let test = body.replace(/<blockquote>/g, '<blockquote class="blockquote">')
    if (test.match('<!--more-->')) {
      test = test.split('<!--more-->')
      if (typeof test[0] !== 'undefined') {
        return test[0]
      }
    }
    return test
  }

  render() {
    const { site, data, isIndex } = this.props
    const title = get(data, 'frontmatter.title')
    const path = get(data, 'frontmatter.path')
    const date = get(data, 'frontmatter.date')
    const excerpt = get(data, 'excerpt')
    let image = get(data, 'frontmatter.image')
    if (image == null) {
      image = 'https://via.placeholder.com/400x300'
    } else {
      image = image.childImageSharp.resize.src;
    }
    return (
      <div className="card-article">
        <div className="row align-items-center">
          <div className="col-md-4">
            <Link to={path}>
              <img className="thumb-article" src={image} />
            </Link>
          </div>
          <div className="col-md-8">
            <div className="p-2">
              <Link to={path}>
                <h1>{title}</h1>
              </Link>
              <time>{date}</time>
              <div className="page-excerpt">
              {excerpt}
              </div>
              <Link className="link-more" to={path}>
                En savoir +
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CardPost