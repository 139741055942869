import React from 'react'
import Link from 'gatsby-link'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import size from 'lodash/size'
import Img from "gatsby-image";
import './style.scss'

class CardOasis extends React.Component {
  description(body) {
    let test = body.replace(/<blockquote>/g, '<blockquote class="blockquote">')
    if (test.match('<!--more-->')) {
      test = test.split('<!--more-->')
      if (typeof test[0] !== 'undefined') {
        return test[0]
      }
    }
    return test
  }

  render() {
    const { site, data, isIndex } = this.props
    const title = get(data, 'frontmatter.title')
    const path = get(data, 'frontmatter.path')
    const date = get(data, 'frontmatter.date')
    const departement = get(data, 'frontmatter.departement')
    const montant = get(data, 'frontmatter.montant')
    const avancement = get(data, 'frontmatter.avancement')
    const description = get(data, 'frontmatter.description')
    //const excerpt = get(data, 'excerpt')
    let image = get(data, 'frontmatter.image')
    if (image == null) {
      image = 'https://via.placeholder.com/400x300'
    } else {
      image = image.childImageSharp.resize.src;
    }
    let classAvancement = 'avancement'
    if (avancement == 'financé') {
      classAvancement = 'avancement financed'
    }
    return (
      <div className="card-oasis">
        <div className="oasis-wrapper">
          <Link style={{ boxShadow: 'none' }} to={path}>
            <div className="moving-group">
              <div className="description">
                {description}
              </div>
              <h2 className="title">
                {title}
              </h2>
              <div className="departement">{departement}</div>
              <img className="thumb-article" src={image} />
            </div>
            <div className="group-sticky">
              <div className={classAvancement}>
                {avancement}
              </div>
              <div className="montant">
                {montant}
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

export default CardOasis