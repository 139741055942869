import React from "react"
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import CardPost from '../components/CardPost'
import CardOasis from '../components/CardOasis'

class HomeIndex extends React.Component {
  render() {
    const site = get(this, 'props.data.site.siteMetadata')
    const posts = get(this, 'props.data.remark.posts')
    const oasis = get(this, 'props.data.oasis.posts')

    // on recupere tous les oasis libres
    const tabCardOasis = []
    oasis.forEach((data, i) => {
      tabCardOasis.push(
          <LazyLoad height={1} offset={500} once={true} key={i}>
            <CardOasis data={data.post} site={site} isIndex={true} key={i} />
          </LazyLoad>
        )
    })

    // on recupere les derniers articles pour mettre en une
    const cardPosts = []
    posts.forEach((data, i) => {
      const layout = get(data, 'post.frontmatter.layout')
      const path = get(data, 'post.path')
      if (layout === 'post' && path !== '/404/') {
        cardPosts.push(
          <LazyLoad height={1} offset={500} once={true} key={i}>
            <CardPost data={data.post} site={site} isIndex={true} key={i} />
          </LazyLoad>
        )
      }
    })

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={get(site, 'title')}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: `@${get(site, 'twitter')}` },
            { property: 'og:title', content: get(site, 'title') },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: get(site, 'description') },
            { property: 'og:url', content: get(site, 'url') },
            {
              property: 'og:image',
              content: `${get(site, 'url')}/img/logo.png`,
            },
          ]}
        />
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-right">
              <h1 className="underlined">Welcome<br />of the website of the<br />eco-village <strong>of Plessis</strong></h1><br />
              Partout en France, des collectifs citoyens inventent de nouveaux lieux de vie écologiques et solidaires : les oasis.<br/>
              C'est parce que les banques ne financent pas ou mal ces projets participatifs que la Coopérative Oasis a été créée. Elle vous permet d'investir dans la création et le développement des oasis.<br/>
              Donnons du sens à notre épargne et construisons ainsi ensemble ces oasis de demain. <Link to="/participer">Investissons dans les oasis !</Link>
            </div>
            <div className="col-md-8">
              <img className="img-responsive" src={'/img/habiterre-kaizen.jpg'} alt="photo" />
            </div>
          </div>
        </div>
        <div className="oasis">
          <div className="container">
            <div className="row">
              <div className="col-md-4 text-right"><h1 className="underlined">Les projets du moment</h1></div>
              <div className="col-md-8"></div>
            </div>
            <div className="grid">
            {tabCardOasis}
            </div>
            <br />
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <Link to="/les-oasis/" className="btn btn-block btn-primary px-5">
                  <i className="fa fa-plus"></i> Voir toutes les oasis
                </Link>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
        <div className="articles">
          <div className="container">
            <div className="row">
              <div className="col-md-3 text-right"><h1 className="underlined">Dernières actus</h1></div>
              <div className="col-md-9"></div>
            </div>
            {cardPosts}
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Link to="/actus/" className="btn btn-block btn-primary px-5">
                  <i className="fa fa-plus"></i> Voir toutes les actualités
                </Link>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex

export const pageQuery = graphql`
  query IndexQueryEn {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    remark: allMarkdownRemark(
      limit:4,
      filter: {
        frontmatter:{
          type:{eq : "blog"}
        }
      },
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      posts: edges {
        post: node {
          fileAbsolutePath
          html
          excerpt(pruneLength: 300)
          frontmatter {
            layout
            title
            path
            categories
            date(formatString: "DD.MM.YYYY")
            type
            image {
              childImageSharp {
                resize(width: 600) {
                  src
                }
              }
            }
            description
          }
        }
      }
    }
    oasis: allMarkdownRemark(
      limit:3,
      filter: {
        frontmatter:{
          type:{eq : "oasis"}
        }
      },
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      posts: edges {
        post: node {
          fileAbsolutePath
          html
          excerpt(pruneLength: 300)
          frontmatter {
            layout
            title
            path
            avancement
            montant
            departement
            image {
              childImageSharp {
                resize(width: 600) {
                  src
                }
              }
            }
            description
          }
        }
      }
    }
  }
`
